<template>
  <b-row>
    <b-col cols="12">
      <b-card :bg-variant="program.organisation_id ? 'light' : ''">
        <b-form-group
          :label-class="`h5`"
          label="Select Organisation"
        >
          <v-select
            v-model="program.organisation_id"
            :get-option-label="option => option.users_organizationtable.title"
            :options="userOrgs"
            :reduce="org => org.organization_id"
            placeholder="Choose one from the list"
          />
        </b-form-group>
      </b-card>

      <b-overlay
        :show="!program.organisation_id"
        rounded="sm"
      >
        <template #overlay>
          <b-card
            bg-variant="primary"
            text-variant="light"
          >
            Please Select Organisation
          </b-card>
        </template>
        <form-wizard
          id="form-wizard"
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!--          Basic Info tab-->
          <tab-content
            :before-change="validationBasicInfo"
            title="Basic Information"
          >
            <validation-observer
              ref="basicInfo"
              tag="form"
            >

              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Program Name"
                    label-for="programTitle"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Program Name"
                      rules="required"
                    >
                      <b-form-input
                        id="programTitle"
                        v-model="program.title"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Program Name"
                        type="text"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Student Capacity"
                    label-for="programCapacity"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Program Capacity"
                      rules="required"
                    >
                      <b-form-input
                        id="programCapacity"
                        v-model="program.capacity"

                        :state="errors.length > 0 ? false:null"
                        placeholder="Number of participants"
                        type="number"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Program Description"
                    label-for="programDescription"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Program Description"
                      rules="required"
                    >
                      <b-form-input
                        id="programDescription"
                        v-model="program.description"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Program Description"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
                <b-col
                  cols="12"
                >
                  <h5 class="mb-0">
                    Criteria
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(item, i) in program.criteria"
                    :key="i"
                    class="mt-2"
                  >
                    <b-col>
                      <b-form-group
                        :label="`Criteria ${i + 1}`"
                        :label-for="`Criteria-${i + 1}`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="`Criteria ${i + 1}`"
                        >
                          <b-form-input
                            :id="`Criteria-${i + 1}`"
                            v-model="item.criteria"
                            :state="errors.length > 0 ? false:null"

                            placeholder="Criteria Title"
                            type="text"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="auto">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-danger"
                        @click="program.criteria.splice(i-1, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        #default="{ errors }"
                        :name="`Criteria ${i + 1} Description`"
                      >
                        <b-form-input
                          :id="`description-${i + 1}`"
                          v-model="item.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Criteria Description"
                          type="text"
                        />

                        <b-form-invalid-feedback :state="errors.length > 0">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div
                        v-if="!program.criteria.length"
                        class="text-muted mt-2"
                      >
                        <feather-icon
                          icon="AlertTriangleIcon"
                        />
                        No criteria added yet.
                      </div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :disabled="program.criteria.filter(item => !item.criteria).length > 0"
                        class="mt-2"
                        size="sm"
                        variant="outline-primary"
                        @click="program.criteria.push({criteria: null, description: null})"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Criteria</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <hr>
                </b-col>
                <b-col
                  cols="12"
                >
                  <h5 class="mb-0">
                    Deliverables
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(item, i) in program.deliverables"
                    :key="i"
                    class="mt-2"
                  >
                    <b-col>

                      <b-form-group
                        :label="`Deliverable ${i + 1}`"
                        :label-for="`Deliverable-${i + 1}`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="`Deliverable ${i + 1}`"
                        >
                          <b-form-input
                            :id="`Deliverable-${i + 1}`"
                            v-model="item.deliverable"
                            :state="errors.length > 0 ? false:null"

                            placeholder="Deliverable Title"
                            type="text"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="auto">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-danger"
                        @click="program.deliverables.splice(i-1, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        #default="{ errors }"
                        :name="`Deliverable ${i + 1} Description`"
                      >
                        <b-form-input
                          :id="`description-${i + 1}`"
                          v-model="item.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Deliverable Description"
                          type="text"
                        />

                        <b-form-invalid-feedback :state="errors.length > 0">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div
                        v-if="!program.deliverables.length"
                        class="text-muted mt-2"
                      >
                        <feather-icon
                          icon="AlertTriangleIcon"
                        />
                        No deliverables added yet.
                      </div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :disabled="program.deliverables.filter(item => !item.deliverable).length > 0"
                        class="mt-2"
                        size="sm"
                        variant="outline-primary"
                        @click="program.deliverables.push({deliverable: null, description: null})"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Deliverable</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Phase Structure tab  -->
          <tab-content
            :before-change="validationPhaseStructure"
            title="Phase Structure"
          >
            <validation-observer
              ref="phaseStructure"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Phase Structure
                  </h5>
                </b-col>
              </b-row>
              <draggable
                :list="program.phases"
                group="phases"
                handle=".phases-drag"
              >
                <div
                  v-for="(phase, index) in program.phases"
                  :id="phase.id"
                  :key="index"
                  class="mb-3"
                >
                  <app-collapse
                    type="border"
                  >
                    <app-collapse-item
                      :title="phase.title"
                      class="phases-drag border-secondary"
                    >
                      <template #header>
                        <div
                          class="d-flex align-items-center position-absolute cursor-move"
                          style="top: -10px;"
                        >
                          <b-badge
                            class="ml-50"
                            variant="dark"
                          >Phase {{ index + 1 }}
                          </b-badge>
                        </div>
                        <span class="h5 mt-1 mb-0 d-flex align-items-center">
                          <validation-provider
                            #default="{ errors }"
                            :name="`Phase ${index+1} Title`"
                            rules="required"
                          >
                            <div class="form-label-group ml-50 my-50">
                              <b-form-input
                                :id="'phase-'+phase.id"
                                v-model.lazy="phase.title"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Phase Title"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0">{{
                                errors[0]
                              }}</b-form-invalid-feedback>
                              <label :for="'phase-'+phase.id">Phase Title</label>
                            </div>

                          </validation-provider>
                        </span>
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          :disabled="program.phases.length < 2"
                          size="sm"
                          variant="outline-danger"
                          @click="program.phases.splice(index, 1)"
                        >
                          <feather-icon
                            class="mr-25"
                            icon="XIcon"
                          />
                          <span>Delete Phase</span>
                        </b-button>
                      </template>
                      <b-row>
                        <b-col
                          md="12"
                        >
                          <div class="form-label-group mb-1 px-50">
                            <b-form-input
                              :id="'phaseDescription-'+phase.id"
                              v-model="phase.description"
                              placeholder="Phase Description"
                              type="text"
                            />
                            <label :for="'phaseDescription-'+phase.id">Phase Description</label>
                          </div>
                        </b-col>
                        <b-col
                          cols="12"
                        >
                          <app-collapse
                            class="shadow-sm rounded mx-50"
                            type="border"
                          >
                            <app-collapse-item
                              title="Objectives"
                            >
                              <div
                                v-if="!phase.objectives.length"
                                class="text-muted "
                              >
                                <feather-icon
                                  icon="AlertTriangleIcon"
                                />
                                No objectives added yet.
                              </div>
                              <div
                                v-for="(objective, i) in phase.objectives"
                                :id="objective.id"
                                :key="objective.id"
                                class="d-flex align-items-center justify-content-between mb-50"
                              >
                                <span class="h6 mb-0 d-flex align-items-center flex-grow-1">
                                  <b-badge
                                    v-b-tooltip="'Objective Number'"
                                    class="mr-50"
                                    variant="primary"
                                  >{{ i + 1 }}
                                  </b-badge>
                                  <b-row class="flex-grow-1"><!-- Item Name -->
                                    <b-col md="4">
                                      <div class="form-label-group my-50">
                                        <b-form-input
                                          :id="'objective-'+objective.id"
                                          v-model="objective.title"
                                          placeholder="Title"
                                        />
                                        <label :for="'objective-'+objective.id">Title</label>
                                      </div>
                                    </b-col>
                                    <b-col md="7">
                                      <div class="form-label-group my-50">
                                        <b-form-input
                                          :id="'objectiveDescription-'+objective.id"
                                          v-model="objective.description"
                                          placeholder="Description"
                                          type="text"
                                        />
                                        <label :for="'objectiveDescription-'+objective.id">Description</label>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </span>
                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  class="mt-0"
                                  size="sm"
                                  variant="outline-danger"
                                  @click="phase.objectives.splice(i, 1)"
                                >
                                  <feather-icon
                                    class="mr-25"
                                    icon="XIcon"
                                  />
                                  <span>Delete Objective</span>
                                </b-button>
                              </div>
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                :disabled="phase.objectives.filter(o => !o.title).length > 0"
                                class="mt-0 mr-1 my-1"
                                size="sm"
                                variant="outline-primary"
                                @click="phase.objectives.push(
                                  {
                                    title: null,
                                    description: null,
                                  })"
                              >
                                <feather-icon
                                  class="mr-25"
                                  icon="PlusIcon"
                                />
                                <span>Add Objective</span>
                              </b-button>
                            </app-collapse-item>
                          </app-collapse>
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                  </app-collapse>
                  <div class="border-left pl-2 pt-2">
                    <app-collapse
                      accordion
                      type="border"
                    >
                      <draggable
                        :list="phase.subphases"
                        group="subphases"
                        handle=".subphases-drag"
                      >
                        <div
                          v-for="(subphase, s) in phase.subphases"
                          :id="subphase.id"
                          :key="s"
                          class="pb-2"
                        >
                          <app-collapse-item
                            :title="subphase.title"
                            class="subphases-drag border-secondary"
                          >
                            <template #header>
                              <div
                                class="d-flex align-items-center position-absolute cursor-move"
                                style="top: -10px;"
                              >
                                <b-badge
                                  class="ml-50"
                                  variant="primary"
                                >Subphase {{ s + 1 }}
                                </b-badge>
                              </div>
                              <span class="h5 mb-0 d-flex align-items-center mt-1">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="`Subphase ${s+1} Title`"
                                  rules="required"
                                >
                                  <div class="form-label-group ml-50 my-50">
                                    <b-form-input
                                      :id="'subphase-'+subphase.id"
                                      v-model.lazy="subphase.title"
                                      :state="errors.length > 0 ? false:null"
                                      placeholder="Subphase Title"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0">{{
                                      errors[0]
                                    }}</b-form-invalid-feedback>
                                    <label :for="'phase-'+phase.id">Subphase Title</label>
                                  </div>
                                </validation-provider>
                              </span>
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                :disabled="phase.subphases.length < 2"
                                size="sm"
                                variant="outline-danger"
                                @click="phase.subphases.splice(s, 1)"
                              >
                                <feather-icon
                                  class="mr-25"
                                  icon="XIcon"
                                />
                                <span>Delete Subphase</span>
                              </b-button>
                            </template>
                            <b-row>
                              <b-col md="12">
                                <div class="form-label-group mb-1 px-50">
                                  <b-form-input
                                    :id="'subphaseDescription-'+subphase.id"
                                    v-model="subphase.description"
                                    placeholder="Subphase Description"
                                    type="text"
                                  />
                                  <label :for="'subphaseDescription-'+subphase.id">Subphase Description</label>
                                </div>
                              </b-col>
                            </b-row>
                          </app-collapse-item>
                        </div>
                      </draggable>
                    </app-collapse>
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      :disabled="phase.subphases.filter(o => !o.title).length > 0"
                      class="mr-1 btn-icon"
                      variant="outline-primary"
                      @click="phase.subphases.push(
                        {
                          title: 'New Subphase',
                          description: null,
                          days: [
                            {
                              activities: [
                                {
                                  title: 'New Activity',
                                  operations_type: null,
                                  duration: null,
                                  description: null,
                                  objectives: [],
                                },
                              ],
                            },
                          ],
                        })"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="PlusIcon"
                      />
                      <span>Add Subphase</span>
                    </b-button>
                  </div>
                </div>
              </draggable>
              <h6 class="text-muted">
                <feather-icon icon="InfoIcon" />
                Drag and drop to reorder phases. Click to edit.
              </h6>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                :disabled="program.phases.filter(phase => !phase.title).length > 0"
                class="mt-0 mr-1 my-1 btn-icon"
                variant="outline-primary"
                @click="program.phases.push(
                  {
                    title: 'New Phase',
                    description: null,
                    objectives: [],
                    subphases: [{
                      title: 'New Subphase',
                      description: null,
                      days: [
                        {
                          activities: [
                            {
                              title: 'New Activity',
                              operations_type: null,
                              duration: null,
                              description: null,
                              objectives: [],
                            },
                          ],
                        },
                      ],
                    }],
                  },)"
              >
                <feather-icon
                  class="mr-25"
                  icon="PlusIcon"
                />
                <span>Add Phase</span>
              </b-button>
            </validation-observer>
          </tab-content>

          <!-- Phase Details tab  -->
          <tab-content
            :before-change="validationPhaseDetails"
            title="Phase Details"
          >
            <validation-observer
              ref="phaseDetails"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Phase Details
                  </h5>
                </b-col>

              </b-row>
              <b-tabs
                v-model="phasesTab"
                pills
              >
                <b-tab
                  v-for="(phase, i) in program.phases"
                  :key="i"
                  active
                >
                  <template
                    #title
                  >
                    <span
                      @dragover="phasesTab = i"
                    >{{ phase.title }}
                      <b-badge
                        class="ml-50 text-primary"
                        variant="light"
                      >
                        Phase {{ i + 1 }}
                      </b-badge></span>
                  </template>
                  <b-row
                    v-for="(subphase, s) in phase.subphases"
                    :id="subphase.id"
                    :key="subphase.id"
                    class="shadow rounded pb-1 mb-2 mx-25"
                  >
                    <b-col class="bg-primary rounded-top">
                      <h5 class="text-white my-1">
                        {{ subphase.title }}
                        <b-badge
                          class="ml-50 text-primary"
                          variant="light"
                        >
                          Subphase {{ s + 1 }}
                        </b-badge>
                      </h5>
                    </b-col>
                    <b-col
                      md="12"
                    >
                      <b-row
                        v-for="(day, j) in subphase.days"
                        :key="j"
                      >
                        <b-col
                          class="mb-2 py-1 bg-light border-top"
                          cols="12"
                        >
                          <span class="h5 mr-1">
                            Day {{ j + 1 }}
                          </span>
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            :disabled="subphase.days.length < 2"
                            class="mt-0"
                            size="sm"
                            variant="outline-danger"
                            @click="subphase.days.splice(j, 1)"
                          >
                            <feather-icon
                              class="mr-25"
                              icon="XIcon"
                            />
                            <span>Delete Day</span>
                          </b-button>
                        </b-col>
                        <b-col
                          class="pb-2"
                          cols="12"
                        >
                          <app-timeline>
                            <draggable
                              :list="day.activities"
                              :move="checkMove"
                              group="activities"
                              handle=".enable-drag"
                            >
                              <app-timeline-item
                                v-for="(activity, k) in day.activities"
                                :key="k"
                              >
                                <b-row
                                  :class="{
                                    'enable-drag cursor-move border-primary': activity.operations_type !== 'holiday',
                                    'border-dark': activity.operations_type === 'holiday',
                                  }"
                                  class="rounded shadow mr-50 p-1 pt-2 border"
                                >
                                  <b-col
                                    class="d-flex align-items-center position-absolute"
                                    style="top: -10px;"
                                  >
                                    <b-badge
                                      v-if="activity.operations_type === 'holiday'"
                                      variant="dark"
                                    >
                                      Holiday
                                    </b-badge>
                                    <b-badge
                                      v-else
                                      variant="primary"
                                    >
                                      Activity {{ k + 1 }}
                                    </b-badge>
                                  </b-col>
                                  <!-- Item Name -->
                                  <b-col>
                                    <b-form-group
                                      :label="`Activity Title`"
                                      :label-for="'activity-'+activity.id"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        :name="`(Phase ${i+1} Subphase ${s+1} Day ${j+1}) Activity ${k+1} Title`"
                                        rules="required"
                                      >
                                        <b-form-input
                                          :id="'activity-'+activity.id"
                                          v-model="activity.title"
                                          :readonly="activity.operations_type === 'holiday'"
                                          :state="errors.length > 0 ? false:null"
                                        />
                                        <b-form-invalid-feedback :state="errors.length > 0">{{
                                          errors[0]
                                        }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                  <b-col
                                    v-if="activity.operations_type !== 'holiday'"
                                    md="3"
                                  >
                                    <b-form-group
                                      :label="`Activity type`"
                                      :label-for="'activityType-'+activity.id"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        :name="`(Phase ${i+1} Subphase ${s+1} Day ${j+1}) Activity ${k+1} Type`"
                                        rules="required"
                                      >
                                        <b-form-input
                                          :id="'activityType-'+activity.id"
                                          v-model="activity.operations_type"

                                          :state="errors.length > 0 ? false:null"
                                        />
                                        <b-form-invalid-feedback :state="errors.length > 0">{{
                                          errors[0]
                                        }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                  <b-col
                                    v-if="activity.operations_type !== 'holiday'"
                                    md="2"
                                  >
                                    <b-form-group
                                      :label-for="'activityDuration-'+activity.id"
                                      label="Activity duration"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        :name="`(Phase ${i+1} Subphase ${s+1} Day ${j+1}) Activity ${k+1} Duration`"
                                        rules="required"
                                      >
                                        <flat-pickr
                                          :id="'activityDuration-'+activity.id"
                                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true, defaultHour:0}"
                                          :state="errors.length > 0 ? false:null"
                                          :value="minutesToTime(activity.duration)"
                                          class="form-control"
                                          @on-change="(selectedDates, dateStr) => {
                                            activity.duration = timeToMinutes(dateStr);
                                          }"
                                        />
                                        <b-form-invalid-feedback :state="errors.length > 0">{{
                                          errors[0]
                                        }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                  <b-col
                                    v-if="activity.operations_type !== 'holiday'"
                                    md="12"
                                  >
                                    <b-form-group

                                      :label="`Description`"
                                      :label-for="'activityDesc-'+activity.id"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        :name="`(Phase ${i+1} Subphase ${s+1} Day ${j+1}) Activity ${k+1} Description`"
                                      >
                                        <b-form-input
                                          :id="'activityDesc-'+activity.id"
                                          v-model="activity.description"
                                          :state="errors.length > 0 ? false:null"
                                        />
                                        <b-form-invalid-feedback :state="errors.length > 0">{{
                                          errors[0]
                                        }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                  <b-col
                                    v-if="activity.operations_type !== 'holiday'"
                                    cols="12"
                                  >
                                    <app-collapse
                                      type="border"
                                    >
                                      <app-collapse-item
                                        class="shadow-sm"
                                        title="Objectives"
                                      >
                                        <div
                                          v-if="!activity.objectives.length"
                                          class="text-muted "
                                        >
                                          <feather-icon
                                            icon="AlertTriangleIcon"
                                          />
                                          No objectives added yet.
                                        </div>
                                        <div
                                          v-for="(objective, o) in activity.objectives"
                                          :id="objective.id"
                                          :key="objective.id"
                                          class="d-flex align-items-center justify-content-between mb-50"
                                        >
                                          <span class="h6 mb-0 d-flex align-items-center flex-grow-1">
                                            <b-badge
                                              v-b-tooltip="'Objective Number'"
                                              class="mr-50"
                                              variant="primary"
                                            >{{ o + 1 }}
                                            </b-badge>
                                            <b-row class="flex-grow-1"><!-- Item Name -->
                                              <b-col md="4">
                                                <div class="form-label-group my-50">
                                                  <b-form-input
                                                    :id="'objective-'+objective.id"
                                                    v-model="objective.title"
                                                    placeholder="Title"
                                                  />
                                                  <label :for="'objective-'+objective.id">Title</label>
                                                </div>
                                              </b-col>
                                              <b-col md="7">
                                                <div class="form-label-group my-50">
                                                  <b-form-input
                                                    :id="'objectiveDescription-'+objective.id"
                                                    v-model="objective.description"
                                                    placeholder="Description"
                                                    type="text"
                                                  />
                                                  <label :for="'objectiveDescription-'+objective.id">Description</label>
                                                </div>
                                              </b-col>
                                            </b-row>
                                          </span>
                                          <b-button
                                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                            class="mt-0"
                                            size="sm"
                                            variant="outline-danger"
                                            @click="activity.objectives.splice(i, 1)"
                                          >
                                            <feather-icon
                                              class="mr-25"
                                              icon="XIcon"
                                            />
                                            <span>Delete Objective</span>
                                          </b-button>
                                        </div>
                                        <b-button
                                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                          :disabled="activity.objectives.filter(o => !o.title).length > 0"
                                          class="mt-0 mr-1 my-1"
                                          size="sm"
                                          variant="outline-primary"
                                          @click="activity.objectives.push(
                                            {
                                              title: null,
                                              description: null,
                                            })"
                                        >
                                          <feather-icon
                                            class="mr-25"
                                            icon="PlusIcon"
                                          />
                                          <span>Add Objective</span>
                                        </b-button>
                                      </app-collapse-item>
                                    </app-collapse>
                                  </b-col>
                                  <b-col
                                    v-if="activity.operations_type !== 'holiday'"
                                    class="mb-50 pt-1"
                                  >
                                    <b-button
                                      v-if="day.activities.length-1 === k"
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      class="mt-0 mr-1"
                                      size="sm"
                                      variant="primary"
                                      @click="day.activities.push(
                                        {
                                          title: null,
                                          operations_type: null,
                                          duration: null,
                                          description: null,
                                          objectives: [
                                          ],
                                        })"
                                    >
                                      <feather-icon
                                        class="mr-25"
                                        icon="PlusIcon"
                                      />
                                      <span>Add Activity</span>
                                    </b-button>
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      class="mt-0"
                                      size="sm"
                                      variant="danger"
                                      @click="day.activities.splice(k, 1)"
                                    >
                                      <feather-icon
                                        class="mr-25"
                                        icon="XIcon"
                                      />
                                      <span>Delete Activity</span>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </app-timeline-item>
                              <app-timeline-item
                                v-if="!day.activities.length"
                              >
                                <div
                                  class="d-flex justify-content-center align-items-center p-5 border rounded flex-wrap"
                                >
                                  <div class="w-100 text-center">
                                    Drag and drop activities here. <br> - or - <br>
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      class="mt-50"
                                      size="sm"
                                      variant="primary"
                                      @click="day.activities.push(
                                        {
                                          title: null,
                                          operations_type: null,
                                          duration: null,
                                          description: null,
                                          objectives: [

                                          ],
                                        })"
                                    >
                                      <feather-icon
                                        class="mr-25"
                                        icon="PlusIcon"
                                      />
                                      <span>Add Activity</span>
                                    </b-button>
                                    <br>
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      class="mt-25"
                                      size="sm"
                                      variant="flat-dark"
                                      @click="day.activities.push(
                                        {
                                          title: 'Holiday',
                                          operations_type: 'holiday',
                                          duration: 90,
                                          description: null,
                                        }
                                      )"
                                    >
                                      <feather-icon
                                        class="mr-25"
                                        icon="XIcon"
                                      />
                                      <span>Mark as Holiday</span>
                                    </b-button>
                                  </div>

                                </div>
                              </app-timeline-item>
                            </draggable>
                          </app-timeline>
                        </b-col>
                        <b-col
                          v-if="subphase.days.length-1 === j"
                          cols="12"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            size="sm"
                            variant="outline-primary"
                            @click="subphase.days.push(
                              {
                                activities: [],
                              })"
                          >
                            <feather-icon
                              class="mr-25"
                              icon="PlusIcon"
                            />
                            <span>Add Day</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-row>
                  <b-col>
                    <b-button
                      v-if="phasesTab > 0"
                      class="mt-0 mt-md-2 btn-icon"
                      variant="outline-primary"
                      @click="phasesTab--"
                      @dragover="phasesTab--"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="ChevronLeftIcon"
                      />
                      <span>Previous Phase</span>
                    </b-button>
                  </b-col>
                  <b-col class="text-right">
                    <b-button
                      v-if="phasesTab < program.phases.length - 1"
                      class="mt-0 mt-md-2 btn-icon"
                      variant="outline-primary"
                      @click="phasesTab++"
                      @dragover="phasesTab++"
                    >
                      <span>Next Phase</span>
                      <feather-icon
                        class="mr-25"
                        icon="ChevronRightIcon"
                      />
                    </b-button>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="12"
                  >
                    <hr>
                  </b-col>
                </b-row>
              </b-tabs>
            </validation-observer>
          </tab-content>

        </form-wizard>

      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge, BCard, BFormGroup, BFormInput, BTab, BTabs,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import draggable from 'vuedraggable'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    AppTimeline,
    AppTimelineItem,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    vSelect,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    draggable,
    flatPickr,
  },
  data() {
    return {
      program: {
        organisation_id: null,
        programId: null,
        title: null,
        type: null,
        description: null,
        capacity: null,
        criteria: [],
        deliverables: [],
        phases: [
          {
            title: 'New Phase',
            description: null,
            objectives: [],
            subphases: [{
              title: 'New Subphase',
              description: null,
              days: [
                {
                  activities: [
                    {
                      title: 'New Activity',
                      operations_type: null,
                      duration: null,
                      description: null,
                      objectives: [],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      phasesTab: null,
      required,
      email,
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      if (!arr.length) return []
      return arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Delete nulls
    },
  },
  methods: {
    checkMove(event) {
      // Disable moving activities to holidays
      return !(event.relatedContext.list.length && event.relatedContext.list[0].operations_type === 'holiday')
    },
    // Convert time to minutes
    timeToMinutes(time) {
      const [hours, minutes] = time.split(':')
      // eslint-disable-next-line no-param-reassign
      return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
    },
    // Convert minutes to time (H:i)
    minutesToTime(time) {
      const hours = Math.floor(time / 60)
      const minutes = time % 60
      return `${hours}:${minutes}`
    },
    /* eslint-disable no-param-reassign */
    formatPhases(phases, programId) {
      const formattedPhases = [...phases]
      formattedPhases.forEach(phase => {
        phase.operations_type = 'Phase'
        phase.program_id = programId
        phase.programs_objectivestables = {
          data: phase.objectives,
        }
        delete phase.objectives
        phase.subphases.forEach(subphase => {
          subphase.operations_type = 'SubPhase'
          subphase.program_id = programId
          const activities = []
          subphase.days.forEach((day, dayIndex) => {
            day.activities.forEach(activity => {
              activity.day = dayIndex + 1
              activity.program_id = programId
              activity.programs_objectivestables = {
                data: activity.objectives,
              }
              delete activity.objectives
              activities.push(activity)
            })
            subphase.programs_operationstables = {
              data: activities,
            }
            delete subphase.days
          })
        })
        phase.programs_operationstables = {
          data: phase.subphases,
        }
        delete phase.subphases
      })
      return formattedPhases
    },
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addProgram()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please Wait! Creating Program...',
          icon: 'LoaderIcon',
          variant: 'info',
        },
      })
    },
    addProgram() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation (
            $criteriaInput: [programs_shortlistingcriteriontable_insert_input!]!,
            $deliverableInput: [programs_deliverablelisttable_insert_input!]!
            ) {
            insert_programs_basicinfo_one(object: {
            status: "draft"
            organization_id_id: ${this.program.organisation_id},
            title: "${this.program.title}",
            type: "skill-dev",
            description: "${this.program.description}",
            capacity: "${this.program.capacity}",
             programs_shortlistingcriteriontables: {data: $criteriaInput},
            programs_deliverablelisttables: {data: $deliverableInput}})
            {
                id
            }
        }`,
        variables: {
          criteriaInput: this.program.criteria.filter(criteria => criteria.criteria),
          deliverableInput: this.program.deliverables.filter(deliverable => deliverable.deliverable),
        },
        update: (store, { data: { insert_programs_basicinfo_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_basicinfo_one.id ? 'Program created. Creating Phases.' : 'Failed to create program',
              icon: insert_programs_basicinfo_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_basicinfo_one.id ? 'info' : 'danger',
            },
          })
          if (insert_programs_basicinfo_one.id) {
            // Program Created, Create Phases
            this.addPhases(insert_programs_basicinfo_one.id)
          } else {
            // Error Occurred, stop loading
            this.mutationLoading = false
          }
        },
      })
      return this.error
    },
    addPhases(programId) {
      this.$apollo.mutate({
        mutation: gql` mutation($objects: [programs_operationstable_insert_input!]!) {
        insert_programs_operationstable(objects: $objects) {
          affected_rows
        }
      }`,
        variables: {
          objects: this.formatPhases(this.program.phases, programId),
        },
        update: (store, { data: { insert_programs_operationstable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_operationstable.affected_rows ? 'Phases created successfully' : 'Failed to create phases',
              icon: insert_programs_operationstable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_operationstable.affected_rows ? 'success' : 'danger',
            },
          })
          this.mutationLoading = false
        },
      })
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfo.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              this.errorToast()
              reject()
            }
          })
      })
    },
    validationPhaseStructure() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseStructure.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              this.errorToast()
              reject()
            }
          })
      })
    },
    validationPhaseDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseDetails.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              this.errorToast()
              reject()
            }
          })
      })
    },
    errorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please fill all the required fields',
          text: this.errors,
          icon: 'XIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
